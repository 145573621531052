document.addEventListener('DOMContentLoaded', () => {
  const swiperRecipes = new Swiper('.swiper.recipes-collection-wrapper', {
    slidesPerView: 4,
    loop: true,
  });

  const swiperTestimonials = new Swiper('.swiper.testimonials', {
    slidesPerView: 1,
    loop: true,
  });

  const swiperAttribution = new Swiper('.swiper.attribution', {
    slidesPerView: 1,
    loop: true,
    allowTouchMove: false,
  });

  swiperTestimonials.controller.control = swiperAttribution;

  const recipesPrev = document.querySelector(
    '.recipes-arrows .button-previous'
  );
  const recipesNext = document.querySelector('.recipes-arrows .button-next');
  const testimonialsPrev = document.querySelector(
    '.testimonial-button-previous'
  );
  const testimonialsNext = document.querySelector('.testimonial-button-next');

  recipesPrev.addEventListener('click', () => {
    swiperRecipes.slidePrev();
  });
  recipesNext.addEventListener('click', () => {
    swiperRecipes.slideNext();
  });

  testimonialsPrev.addEventListener('click', () => {
    swiperTestimonials.slidePrev();
  });

  testimonialsNext.addEventListener('click', () => {
    swiperTestimonials.slideNext();
  });

  const recipeCards = document.querySelector(
    '.recipes-collection.swiper-wrapper'
  );
  const body = document.querySelector('body');
  const recipeModals = document.querySelectorAll('.recipe-modal');

  let activeModal;

  recipeModals.forEach((modal) => {
    const close = modal.querySelector('.recipe-modal-close');
    close.addEventListener('click', closeModal);
  });

  function openModal(modal) {
    activeModal = modal;
    body.append(modal);
    modal.classList.add('show');
  }

  function closeModal() {
    const parent = document.querySelector(`[data-recipe="${activeModal.id}"]`);
    activeModal.classList.remove('show');
    parent.append(activeModal);
    body.classList.remove('modal-open');
    activeModal = null;
  }

  recipeCards.addEventListener('click', (e) => {
    let recipe = e.target.closest('.recipe-carousel-card')?.dataset?.recipe;
    if (!recipe) {
      recipe = e.target.querySelector('.recipe-carousel-card')?.dataset?.recipe;
    }
    const modal = recipeCards.querySelector(`#${recipe}`);
    if (!activeModal) {
      openModal(modal);
    }
  });

  const hero = document.querySelector('.hero');
  const footer = document.querySelector('footer');

  function hideHero(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        hero.style.display = 'none';
      } else {
        hero.style.display = 'block';
      }
    });
  }

  const footerObserver = new IntersectionObserver(hideHero);

  footerObserver.observe(footer);

  const productCardWrappers = document.querySelectorAll(
    '.product-card-wrapper'
  );

  productCardWrappers.forEach((card) => {
    const color = card.querySelector('.product-hover-color').style
      .backgroundColor;
    card.style.setProperty('--hover-color', color);
  });

  let down = false;
  let dragged = false;
  let startX;
  let scrollLeft;

  function preventClick(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  function mouseDown(e) {
    down = true;
    startX = e.pageX - e.currentTarget.offsetLeft;
    scrollLeft = e.currentTarget.scrollLeft;
  }

  function mouseMove(e) {
    if (!down) return;
    e.preventDefault();
    dragged = true;
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 1.2;
    e.currentTarget.scrollLeft = scrollLeft - walk;
  }

  function leave(e) {
    down = false;
    if (dragged) {
      e.target.addEventListener('click', preventClick);
    } else {
      e.target.removeEventListener('click', preventClick);
    }
    dragged = false;
  }

  const pressWrapper = document.querySelector('.press-grid-wrapper');
  if (pressWrapper) {
    pressWrapper.addEventListener('mousedown', mouseDown);
    pressWrapper.addEventListener('mousemove', mouseMove);
    pressWrapper.addEventListener('mouseup', leave);
    pressWrapper.addEventListener('mouseleave', leave);
  }
});
